<template>
    <div class="section_page pb-60">
        <div class="title padding_t">Upload Time</div>
        <div class="text pt-20 padding_t pb-22">Please make sure to upload your file within 1 minute, or the commission
            will be reduced by 50%. Failing to upload within 2 minutes will result into losing the entire commission.
        </div>
        <div class="title padding_t">Files</div>
        <div class="text pt-20 padding_t">Please click on the Download button below, to start editing the image.</div>
        <div class="d-flex justify-content-center mt-4" @click="downloadStyleGuides">
            <div class="btn_dsg" >
                Download style guides
                <img content="All the additional files that might be needed to complete the job - ex: logos, watermarks, color palette, etc" v-tippy="{ placement : 'top',  arrow: true }" class="info_btn-dsg" src="@/assets/icons/info.svg" alt=""/>
            </div>
        </div>
        <div class="pt-110 pb_images images">
            <div class="img">
                <img v-if="image_preview_url === 'tif'" src="@/assets/images/tif.png" alt=""/>
                <img v-else-if="image_preview_url" :src="image_preview_url" alt=""/>
                <img v-else src="@/assets/images/raw.png" alt=""/>
            </div>
            <div class="img_items_d">
                <div class="img_d im1"/>
                <div class="img_d im2"/>
            </div>
        </div>
        <div class="box_f">
            <div class="items_b" @click.prevent="downloadItem({ url:imageWork(), label: 'example' })">
                <div class="pb-22 mpb-16"><img src="@/assets/icons/download.svg" alt=""/></div>
                <div class="text_2">download</div>
            </div>
            <div class="items_b opacity">
                <div class="pb-22"><img src="@/assets/icons/upload.svg" alt=""/></div>
                <div class="text_2">upload</div>
            </div>
        </div>
        <div class="modal fade " id="timeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        Files have been downloaded. Timer will automatically start in 15 seconds
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {errorMessage} from "../services/messages";
import axios from "axios";
import JobWorkStepsEnum from "../enums/JobWorkStepsEnum";
import {Modal} from "bootstrap";
import {Promise} from "bluebird";
import JsZip from "jszip";
import FileSaver from 'file-saver';
import ('../css/custom.css');

export default {
    name: "JobEditorStep1",
    props: ['job', 'work_job', 'index_img', 'image'],
    data() {
        return {
            work_image: {},
            downloadTimer: null,
            attempts: 15,
            downloadToken: null,
            timer_start_work: null,
            update_timer_start_work: null,
            start_timer: 0,
            timeModal: null,
            style_guide_files: [],
            image_preview_url: null
        };
    },
    destroyed() {
        if (this.timer_start_work) {
            clearInterval(this.timer_start_work);
        }
        if (this.update_timer_start_work) {
            clearInterval(this.update_timer_start_work);
        }
        if (this.timeModal) {
            document.body.style.overflow = "scroll";
            document.querySelector('body').classList.remove('modal-open');
            this.timeModal.dispose();
        }
    },
    updated() {
        if (this.getWorkJobTimer) {
            this.timeModal.show();
            this.start_timer = (+this.getWorkJobTimer);
            this.timerStartWork();
            this.saveTimer();
        }
    },
    mounted() {
        this.askNotificationPermission();
        this.timeModal = new Modal(document.getElementById('timeModal'));
        this.getUserStyleGuide();
    },
    computed: {
        getWorkJobTimer() {
            return this.work_job?.timer || null;
        },
    },
    created() {
        window.addEventListener('beforeunload', this.handler);
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'askNotificationPermission',
        ]),
        async getUserStyleGuide() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/get-file-job/`+this.$route.params.id);
                this.style_guide_files = response?.data?.data|| [];
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        handler: function handler(event) {
            if (this.getWorkJobTimer && this.start_timer) {
                this.updateTimerWorkJob();
            }
        },
        async downloadStyleGuides() {
           try {
                this.showLoader();
                let images_url = [];
                let name_files = [];
                let type_files = this.style_guide_files.filter(n => n).map(img=>{
                    if (!!img && !images_url.includes(img.file_url)) {
                        images_url.push(img.file_url);
                        name_files.push(img.file_name);
                        return img.type;
                    }
                })
               if (images_url.length > 0) {
                    //WEIRD HOT FIX TO REMOVE WWW
                    images_url.forEach(image => {
                        image.replace("www.strobeart.com", "strobeart.com");
                    });
                    const download = url => {
                        return fetch(url).then(resp => resp.blob());
                    };
                    const downloadByGroup = (urls, files_per_group=5) => {
                        return Promise.map(
                                urls,
                                async url => {
                                    return await download(url);
                                },
                                {concurrency: files_per_group}
                        );
                    }
                    const exportZip = blobs => {
                        const zip = JsZip();
                        blobs.forEach((blob, i) => {
                            zip.file(`${name_files[i]}.${type_files[i]}`, blob);
                        });
                        zip.generateAsync({type: 'blob'}).then(zipFile => {
                            const currentDate = new Date().getTime();
                            const fileName = `workedFiles-${currentDate}.zip`;
                            return FileSaver.saveAs(zipFile, fileName);
                        });
                        this.hideLoader();
                    }
                    return downloadByGroup(images_url, images_url.length).then(exportZip);
                } else {
                    errorMessage('Unfortunately, no files have been uploaded');
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
                this.hideLoader();
            }
            this.hideLoader();
        },
        async updateTimerWorkJob() {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}update-time-download`, {
                    work_job_id: this.work_job.id,
                    timer: this.start_timer
                });
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        imageWork() {
            return this.image?.src || '';
        },
        async startWork() {
            try {
                this.showLoader();
                const response = await this.$http.postAuth(`${this.$http.apiUrl()}create-work-image`, {
                    work_job: this.work_job.id,
                    number_file: this.index_img,
                    image_id: this.image?.id || null,
                    image_jobs_id: this.job.id
                });
                this.work_image = response?.data?.data || {};
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message)
            }
            this.hideLoader();
            this.$emit('jobEditorStep1', JobWorkStepsEnum.JOB_WORK_STEP_2);
            this.$emit('workImage', this.work_image);
        },
        timerStartWork() {
            if (!this.timer_start_work) {
                this.timer_start_work = window.setInterval(() => {
                    if (this.start_timer >= 15) {
                        this.timeModal.hide();
                        clearInterval(this.update_timer_start_work);
                        clearInterval(this.timer_start_work);
                        this.startWork();
                    }
                    ++this.start_timer;
                }, 1000);
            }
        },
        saveTimer() {
            if (!this.update_timer_start_work) {
                this.update_timer_start_work = window.setInterval(() => {
                    this.updateTimerWorkJob();
                }, 3000);
            }
        },
        async downloadItem({url, label}) {
            this.timeModal.show();
            this.timerStartWork();
            this.saveTimer();
            try {
                let file_name = url.split("/").pop();
                let extension = file_name.split(".").pop();
                axios.get(url, {responseType: "blob"}).then(response => {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.download = label + "." + extension;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                });
                /*const response = await axios.get(url, {responseType: "blob"});
                const blob = new Blob([response.data], {type: response.data.type});
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = label;
                link.click();
                window.URL.revokeObjectURL(link.href);*/
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        },
    },
    watch: {
        image: {
            handler: function() {
                let image_path = (this.image?.src_cropped ?? this.image?.src) || '';
                if(image_path) {
                    let file_name = image_path.split("/").pop();
                    let extension = file_name.split(".").pop();
                    if (extension.toLowerCase() === 'tiff') {
                        try {
                            //image_path = image_path.replace('/storage', '');
                            axios.get(image_path, {responseType: "blob"}).then(response => {
                                const blob = new Blob([response.data], {type: response.data.type});
                                const reader = new FileReader();
                                reader.onload = e => {
                                    let tiff = new Tiff({buffer: e.target.result});
                                    let canvas = tiff.toCanvas();
                                    this.image_preview_url = canvas.toDataURL();
                                }
                                reader.readAsArrayBuffer(blob);
                            });
                        } catch (e) {
                            this.image_preview_url = 'tif';
                        }
                    }
                    else if (extension.toLowerCase() === 'gif' || extension.toLowerCase() === 'webp' || extension.toLowerCase() === 'bmp' ||
                        extension.toLowerCase() === 'jpeg' || extension.toLowerCase() === 'jpg' || extension.toLowerCase() === 'png') {
                        this.image_preview_url = image_path;
                    }
                    else {
                        this.image_preview_url = "";
                    }
                }
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.pb_images {
    padding-bottom: 112px;
}
.section_page {
    padding-top: 102px;
}
.box_f {
    display: flex;
    justify-content: center;
    gap: 40px;
}
.items_b {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    width: 130px;
}
.title {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
    color: #494949;
}
.text {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #494949;
}
.text_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    color: black;
}
.images {
    display: flex;
    justify-content: center;
    padding-left: 34px;
}
.img_d {
    background: #F4F4F3;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    width: 370px;
    height: 354px;
}
.img {
    //background: url("../assets/images/test_img.png");
    background-size: cover;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    width: 370px;
    height: 354px;
    z-index: 1;
}
.img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
}
.img_items_d {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    width: 370px;
    height: 354px;
    position: absolute;
}
.im1 {
    position: absolute;
    left: -44px;
    top: -44px;
}
.im2 {
    position: absolute;
    top: -22px;
    left: -22px;
}
.opacity {
    opacity: 0.5;
}
.btn_dsg {
    cursor: pointer;
    height: 60px;
    width: 239px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
   justify-content: center;
    color: #494949;
    background: white;
    border: 0.5px solid #494949;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    position: relative;
}
.info_btn-dsg {
    position: absolute;
    right: 17px;
    top: 9px;
}

@media only screen and (max-width: 992px) {
    .title {
        font-size: 18px;
        line-height: 22px;
    }
    .text {
        font-size: 12.5px;
        line-height: 20px;
    }
    .img {
        // background: url("../assets/images/test_img.png");
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
        border-radius: 5px;
        width: 284px;
        height: 272px;
        z-index: 1;
        background-size: cover;
    }
    .img_items_d {
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
        border-radius: 5px;
        width: 284px;
        height: 272px;
        position: absolute;
    }
    .img_d {
        background: #F4F4F3;
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
        border-radius: 5px;
        width: 284px;
        height: 272px;
    }
    .im1 {
        position: absolute;
        left: -34px;
        top: -34px;
    }
    .im2 {
        position: absolute;
        top: -17px;
        left: -17px;
    }
    .section_page {
        padding-top: 50px;
        padding-bottom: 60px;
    }
    .pb_images {
        padding-bottom: 90px;
        padding-top: 100px;
    }
    .padding_t {
        padding: 0 19px;
    }
    .text_2 {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: black;
    }
    .mpb-16 {
        padding-bottom: 16px;
    }
    .box_f {
        gap: 0;
    }
    .items_b {
        width: 120px;
    }
}
</style>
