<template>
    <div class="section_page pb-60">
        <div class="title padding_t">Files</div>
        <div class="text pt-20 padding_t">Upload the edited files. You will get paid as soon as the file is approved</div>
        <br>
        <div class="text pt-20">
            If you need to upload more than one picture, please do it with the Archiver using the following extensions for uploading: <strong>.rar, .zip, .7z</strong>
        </div>
        <br>
        <div class="text pt-20">
            Please note that files exceeding <strong>20 Megapixels are not supported</strong>
        </div>
        <div class="row mt-3">
            <div class="col-lg-4 col-12 text-center">
                <div class="timer_section pt-110 text-center mb-4">
                    <vue-ellipse-progress
                            :progress=progress_timer*progress_timer_procent
                            :angle="90"
                            color="#C4C4C4"
                            :size="305"
                            :thickness="10"
                            :legend="false">
                        <p class="timer" slot="legend-caption">
                            <span>{{ workTime.minutes < 10 ? '0' + workTime.minutes : workTime.minutes }}</span>
                            :
                            <span>{{ workTime.seconds < 10 ? '0' + workTime.seconds : workTime.seconds }}</span>
                        </p>

                    </vue-ellipse-progress>
                </div>
                <span v-if="!add_time_upload" class="mt-4">
                    1. Don’t miss the deadline! Upload your file(s) before the timer ends to receive the full commission. Files uploaded after the timer expires will receive only 50% of the commission
                </span>
                <span v-else class="mt-4">
                    2. When uploading after timer expires, the commission will be canceled
                </span>
            </div>
            <div class="col-lg-5 col-12">
                <div class="pt-110 pb_images images">
                    <div class="img">
                        <div class="img_d d-flex justify-content-center align-items-center">
                            <img class="img_d2" src="@/assets/images/bi_image.png" alt=""/>
                        </div>
                    </div>
                    <div class="img_items_d">
                        <div class="img_d im1"/>
                        <div class="img_d im2"/>
                    </div>
                </div>
                <div class="box_f">
                    <div class="items_b opacity">
                        <div class="pb-22"><img src="@/assets/icons/download.svg" alt=""/></div>
                        <div class="text_2">download</div>
                    </div>
                    <div class="items_b " @click="uploadFile">
                        <input id="fileUpload" type="file"  @change="uploadFileChange" hidden>
                        <div class="pb-22"><img src="@/assets/icons/upload.svg" alt=""/></div>
                        <div class="text_2">upload</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 mt-4 mt-lg-0">
                <div class="title m_title mpt5 text-center">Requirements</div>
                <Requirements :job="job"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {errorMessage} from "../services/messages";
import JobWorkStepsEnum from "../enums/JobWorkStepsEnum";
import {VueEllipseProgress} from "vue-ellipse-progress";
import Requirements from "./Requirements";
import ('../css/custom.css');

export default {
    name: "JobEditorStep3",
    props: ['work_image', 'job'],
    components: {
        Requirements,
        VueEllipseProgress
    },
    data() {
        return {
            uploaded_image: null,

            time_sec_1min: 60,
            stop_timer_sec: 60,
            progress_timer_procent: 1.666666667,
            progress_timer: 0,
            timer: 0,
            interval_work_time: null,
            workTime: {
                'hours': 0,
                'minutes': 0,
                'seconds': 0
            },
            stop_timer: false,
            start_timer: true,
            add_time_upload: null,
        };
    },
    mounted() {
        this.timer = this.time_sec_1min;
        this.startTimer();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        uploadFile() {
            document.getElementById("fileUpload").click();
        },
        async uploadFileChange(e) {
            const tmpFiles = e.target.files;
            if (tmpFiles.length === 0) {
                return false;
            }
            this.uploaded_image = tmpFiles[0];
            if (this.uploaded_image) {
                try {
                    this.showLoader();
                    let data = new FormData();
                    data.append('file', this.uploaded_image);
                    data.append('work_image_id', this.work_image.id);
                    await this.$http.postAuth(`${this.$http.apiUrl()}upload-work-image`, data, this.$http.formDataHeader());
                    clearInterval(this.interval_work_time);
                    this.$emit('jobEditorStep3', JobWorkStepsEnum.JOB_WORK_STEP_4)
                } catch (e) {
                    const message = e?.response?.data?.error?.message || 'ERROR';
                    errorMessage(message);
                }
                this.hideLoader();
            }
        },
        startTimer() {
            this.interval_work_time = setInterval(() => {
                if (this.progress_timer === this.stop_timer_sec - 1) {
                    if (!this.add_time_upload) {
                        this.progress_timer = 0;
                        this.timer = 60;
                        this.add_time_upload = true;
                        this.updateTimeUpload();
                    } else {
                        clearInterval(this.interval_work_time);
                        this.stopTimer();
                    }
                }
                this.checkTimeMeeting2(--this.timer);
                ++this.progress_timer;
            }, 1000);
            this.stop_timer = true;
            this.start_timer = false;
        },
        async stopTimer() {
            this.stop_timer = false;
            this.start_timer = true;
            const response = await this.$http.postAuth(`${this.$http.apiUrl()}decline-job-after-timer/${this.$route.params.id}`);
            if (response?.data?.success) {
                this.$router.push({to: '/', name: "Home"}).then();
            }
        },
        async updateTimeUpload() {
            await this.$http.postAuth(`${this.$http.apiUrl()}update-time-upload-work-image`, {
                'work_image_id': this.work_image?.id || null,
                'add_overdue_upload': true
            });
        },
        checkTimeMeeting2(secs2) {
            let secs = secs2;
            this.workTime.hours = Math.floor(secs / 3600);
            secs = secs % 3600;
            this.workTime.minutes = Math.floor(secs / 60);
            this.workTime.seconds = secs % 60;
        },
    }
}
</script>

<style lang="scss" scoped>
.pb_images {
    padding-bottom: 112px;
}
.section_page {
    padding-top: 102px;
}
.box_f {
    display: flex;
    justify-content: center;
    gap: 40px;
}
.items_b {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
}
.title {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
    color: #494949;
}
.text {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #494949;
}
.text_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    color: black;
}
.images {
    display: flex;
    justify-content: center;
    padding-left: 34px;
}
.img_d {
    background: #F4F4F3;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    width: 370px;
    height: 354px;
}
.img {
    background-size: cover;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    width: 370px;
    height: 354px;
    z-index: 1;
}
.img_items_d {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    width: 370px;
    height: 354px;
    position: absolute;
}
.im1 {
    position: absolute;
    left: -44px;
    top: -44px;
}
.im2 {
    position: absolute;
    top: -22px;
    left: -22px;
}
.opacity {
    opacity: 0.5;
}
.img_d2 {
    width: 67px;
    height: 67px;
}

@media only screen and (max-width: 1280px) {
    .img_d, .img, .img_items_d {
        width: 200px;
        height: 200px;
    }
}
@media only screen and (max-width: 992px) {
    .title {
        font-size: 18px;
        line-height: 22px;
    }
    .text {
        font-size: 12.5px;
        line-height: 20px;
    }
    .img {
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
        border-radius: 5px;
        width: 284px;
        height: 272px;
        z-index: 1;
        background-size: cover;
    }
    .img_items_d {
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
        border-radius: 5px;
        width: 284px;
        height: 272px;
        position: absolute;
    }
    .img_d {
        background: #F4F4F3;
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
        border-radius: 5px;
        width: 284px;
        height: 272px;
    }
    .im1 {
        position: absolute;
        left: -34px;
        top: -34px;
    }
    .im2 {
        position: absolute;
        top: -17px;
        left: -17px;
    }
    .section_page {
        padding-top: 50px;
    }
    .pb_images {
        padding-bottom: 100px;
    }
    .padding_t {
        padding: 0 19px;
    }
}
</style>
